import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useLocation, useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import OverAmountTable from './ThreeOverAmountTable';

function ThreeAdminBuyScreen() {
  const navigate = useNavigate();
  const location = useLocation()
  const {authTokens, userType, threeDate, handleLogout} = useAuth()
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [historyAll, setHistoryAll] = useState(location?.state?.historyAll===null || undefined? []: location?.state?.historyAll)
  const [users, setUsers] = useState(location?.state?.users===null || undefined? []: location?.state?.users)
  const [getLimit, setGetLimit] = useState(location?.state?.limit===null || undefined? []: location?.state?.limit)

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimit&adminid='+userType?.id
                      }),
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                        setGetLimit(newdata)
                        setUsers(response?.[1]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, threeDate?.part, threeDate?.month])
  
	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      })
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [currentDate, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])
  
  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 4
      } else if(data?.includes('A') ||
      data?.includes('H')
      ) {
        return 1
      } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
        return 2
      } 
      else {
        return 3
      }
    }
  }
  const mixingDigit = (digit, first, second, uid) => {
    var arr = digit?.split('');
    const makeDigit = [
      arr[0]+''+arr[1]+''+arr[2],
      arr[0]+''+arr[2]+''+arr[1],
      arr[1]+''+arr[0]+''+arr[2],
      arr[1]+''+arr[2]+''+arr[0],
      arr[2]+''+arr[0]+''+arr[1],
      arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
      if(index === 0){
        return ({ 'digit': item, 'amount': first, 'userid':uid })
      } else {
        return ({ 'digit':item, 'amount': second, 'userid':uid })
      }
    });
    return outputArray
  }
  
  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
    if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,3)
      return mixingDigit(firstFirst, first, second, uid)
    } else if(digit.includes('A')){
      return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('H')){
      return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('M')){
      var newMDigit = digit?.replace('M','')
      var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
      return digitMFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(result?.includes('S')){
      var splitSDigit = result?.split('S')
      var amountS = splitSDigit?.[0]?.length === 0? 
      splitSDigit?.[1]?.slice(2) :
      splitSDigit?.[0]?.length === 1 ?
      splitSDigit?.[1]?.slice(1) :
      splitSDigit?.[1]
      var firstDigit = ''
      var secondDigit = ''
      var thirdDigit = ''
      var sDigitArr = []
      if(splitSDigit?.[0]?.length === 0){
        secondDigit = splitSDigit?.[1]?.[0]
        thirdDigit = splitSDigit?.[1]?.[1]
        sDigitArr = ['0'+secondDigit+thirdDigit, 
          '1'+secondDigit+thirdDigit,
          '2'+secondDigit+thirdDigit,
          '3'+secondDigit+thirdDigit,
          '4'+secondDigit+thirdDigit,
          '5'+secondDigit+thirdDigit,
          '6'+secondDigit+thirdDigit,
          '7'+secondDigit+thirdDigit,
          '8'+secondDigit+thirdDigit,
          '9'+secondDigit+thirdDigit,
        ]
      }else if(splitSDigit?.[0]?.length === 1){
        firstDigit = splitSDigit?.[0]
        thirdDigit = splitSDigit?.[1]?.[0]
        sDigitArr = [firstDigit+'0'+thirdDigit, 
          firstDigit+'1'+thirdDigit,
          firstDigit+'2'+thirdDigit,
          firstDigit+'3'+thirdDigit,
          firstDigit+'4'+thirdDigit,
          firstDigit+'5'+thirdDigit,
          firstDigit+'6'+thirdDigit,
          firstDigit+'7'+thirdDigit,
          firstDigit+'8'+thirdDigit,
          firstDigit+'9'+thirdDigit,
        ]
      }else {
        firstDigit = splitSDigit?.[0]?.[0]
        secondDigit = splitSDigit?.[0]?.[1]
        sDigitArr = [firstDigit+secondDigit+'0', 
          firstDigit+secondDigit+'1',
          firstDigit+secondDigit+'2',
          firstDigit+secondDigit+'3',
          firstDigit+secondDigit+'4',
          firstDigit+secondDigit+'5',
          firstDigit+secondDigit+'6',
          firstDigit+secondDigit+'7',
          firstDigit+secondDigit+'8',
          firstDigit+secondDigit+'9',
        ]
      }
      return sDigitArr.map(item => ({'digit':item,'amount':amountS, 'userid':uid}))
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
    }
    return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }
  
  const useFilteredDetail = (detailAll, check) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === check;
      });
    }, [detailAll,check]);
  };

  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');

  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  const buyDetailAmount = useMemo(() => {
    if (!newBuyDetail || newBuyDetail.length === 0) return [];
    
    const map = newBuyDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10);
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newBuyDetail]);

  const detailAmount = useMemo(() => {
    if (!newDetail || newDetail.length === 0) return [];
    
    const map = newDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10);
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newDetail]);

  const outAmountArr = detailAmount?.map(item=>({...item, 'buy':0}))
  const outBuyArr = buyDetailAmount?.map(item => ({
    'digit':item.digit,
    'amount':0,
    'buy':item.amount
  }))

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  var limitOverDigit = outAmountArr?.filter((amount) => amount?.amount > parseInt(getLimit?.[0]?.limit!==undefined?getLimit?.[0]?.limit:'0'))

  var finalCombineArr = limitOverDigit.concat(outBuyArr)

  const combineAmountBoth = Object.values((finalCombineArr || []).reduce((acc, { digit, amount, buy, ...r }) => {
    const key = Object.entries(digit).join('-');
    if (!acc[key]) {
      acc[key] = { digit, amount: 0, buy: 0, ...r };
    }
    acc[key].amount += parseInt(amount);
    acc[key].buy += parseInt(buy);
    return acc;
  }, {}));

  var sumTotal = []
  return (
    <div className='adminScreen'>
      
      <div className='adminTop'>
      <Button
        onClick={() => navigate('/home')} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeadminbuy')} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeprofitloss', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users,
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/threesummary', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users,
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Summary
        </Button>
        <Button
        onClick={() => navigate('/threeaccount', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='thirdBox'>
          <div>
          <h4 style={{fontFamily:'mmfont'}}>ဒိုင်အဝယ်စာရင်းသွင်းရန်</h4>
            <Button
            size='small'
            style={{
            marginTop:5,
            fontSize:16,
            textDecoration:'none',
            textTransform:'capitalize',
            backgroundColor:'#6798c6',
            color:'white',
            fontFamily:'mmfont'
            }}
            onClick={() => navigate('/threebuyinput')} 
            >
            စာရင်းသွင်းရန်နှိပ်ပါ
            </Button>
          </div>
          <div style={{height:0.5, width:'98%', backgroundColor:'rgba(0,0,0,0.09)', marginLeft:'auto', marginRight:'auto', marginTop:10, marginBottom:10}}/>
          <h4 style={{fontFamily:'mmfont'}}>ခေါင်းကျော်နေသော အကွက်များ</h4>
          <OverAmountTable
          combineAmountBoth={combineAmountBoth}
          getLimit={getLimit}
          sumTotal={sumTotal}
          />
        </div>
      </div>
    </div>
  )
}

export default ThreeAdminBuyScreen
