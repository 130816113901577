import React, { useEffect, useState, useMemo } from 'react';
import './HomeScreen.css';
import './AdminHomeScreen.css';
import TextField from '@mui/material/TextField';
import { Box, DialogContentText, MenuItem, Select, Switch } from '@mui/material';
import Button from '@mui/material/Button';
import {mainUrl} from './url';
import {useNavigate} from 'react-router-dom';
import moment from 'moment-timezone';
import axios from 'axios';
import { useAuth } from '../Auth';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ThreeDigitArr from './ThreeDigitArr';
//import OverAmountTable from './OverAmountTable';
import ThreeAdminLimitInput from './ThreeAdminLimitInput';

function ThreeAdminHomeScreen() {
  const navigate = useNavigate();
  const {authTokens, userType, handleLogout, threeDate} = useAuth()
  
  useEffect(() => {
    const backToLogin = () => {
      navigate('/')
    }
    if(!authTokens){
      backToLogin()
    }
  },[authTokens, navigate])
  const [currentDate, setCurrentDate] = useState(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A'))
  const [historyAll, setHistoryAll] = useState([])
  const [lucky, setLucky] = useState('')
  const [getLucky, setGetLucky] = useState([])
  const [limit, setLimit] = useState('0')
  const [getLimit, setGetLimit] = useState([])
  const [allLucky, setAllLucky] = useState([])
  const [limitreload, setLimitReload] = useState(0)
  const [luckyreload, setLuckyReload] = useState(0)
  const [open, setOpen] = useState(false);
  const [removeopen, setRemoveOpen] = useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [currentDigit, setCurrentDigit] = useState('')
  const [username, setUsername] = useState('allagents')
  const [updateUser, setUpdateUser] = useState(0)
  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const handleChangeChecked = async(data, digit, userId) => {
    let createUrl = mainUrl+'api.php?op=threeeditUserLimit';
      var dataArr = data?.split(',')
      
      if(dataArr?.includes(digit)){
        dataArr = dataArr?.filter((item) => item !== digit)
      }else {
        dataArr?.push(digit)
      }
      const finalData = dataArr?.join()===''?'null':dataArr?.join()
      await fetch(createUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit="+finalData+'&id='+userId
      }).then(() => {
        setUpdateUser(updateUser + 1)
      })     
  }

  const removeAgentLimit = async(username) => {
    let removeAllUrl = mainUrl+'api.php?op=threeremoveUserLimit';
    let removeUrl = mainUrl+'api.php?op=threeeditUserLimit';
    if(username === 'allagents'){
      await fetch(removeAllUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit=null&adminid="+userType?.id
      }).then(() => {
        setUpdateUser(updateUser + 1)
        removehandleClickOpen()
      })
    } else {
      await fetch(removeUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"closedDigit=null&id="+username
      }).then(() => {
        setUpdateUser(updateUser + 1)
        removehandleClickOpen()
      })
    }
  }

  const handleClickOpen = (digit) => {
    setCurrentDigit(digit)
    setOpen(true);
    setScroll('paper');
  };
  const handleClose = () => {
    setOpen(false);
  };

  const removehandleClickOpen = () => {
    setRemoveOpen(true);
  };
  const removehandleClose = () => {
    setRemoveOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  var inputDate = moment().tz('Asia/Yangon').format();

  const numarray = Array(1000).fill(0).map((n, i) => { 
    if((n + i) < 10){
      return '00'+String(n+i)
    }else if((n + i) >= 10 && (n + i) < 100) {
      return '0'+String(n+i)
    } 
    else {
      return String(n + i)
    }
  })

  const [users, setUsers] = useState([])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLimit&adminid='+userType?.id
                      })
                    ]).then(response => {
                        var data = response?.[0]?.['data']['data']['result']
                        var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                        setLimit(newdata?.[0]?.limit === undefined?'0':newdata?.[0]?.limit)
                        setGetLimit(newdata)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [limitreload, userType?.id, threeDate?.part, threeDate?.month])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threegetLucky&adminid='+userType?.id
                      })
                    ]).then(response => {
                      var data = response?.[0]?.['data']['data']['result']
                      var newdata = data?.filter((dat) => dat?.daypart === threeDate?.part && dat?.date === threeDate?.month)
                      setLucky(newdata?.[0]?.lucky)
                      setGetLucky(newdata)
                      setAllLucky(data)
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [luckyreload, userType?.id, threeDate?.part, threeDate?.month])

  useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threeuserall&adminid='+userType?.id
                      })
                    ]).then(response => {
                        setUsers(response?.[0]?.['data']['data']['result'])
                    })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [userType?.id, updateUser])

	useEffect(() => {
		let isSubscribed = true;
        if(isSubscribed){
            (async function(){
                try{
                    await axios.all([
                      axios({
                        method:'get',
                        url: mainUrl+'api.php?op=threesaveHistoryAllFilter&adminid='+userType?.id+'&dateFrom='+threeDate?.dateFrom+'&dateTo='+threeDate?.dateTo
                      }),
                    ]).then(response => {
                        setHistoryAll(response?.[0]?.['data']['data']['result'])
                      })
                } catch (error) {
                    
                }
            })()
        }
		return () => {isSubscribed = false}
  }, [currentDate, userType?.id, threeDate?.dateFrom, threeDate?.dateTo])
  
  const defineSplitlens = (data) => {
    if(data === ''){
      return 0
    } else {
      if(data?.includes('R')){
        return 4
      } else if(data?.includes('A') ||
      data?.includes('H')
      ) {
        return 1
      } else if(data?.includes('F') || data?.includes('M') || data?.includes('L') ){
        return 2
      } 
      else {
        return 3
      }
    }
  }
  
  const mixingDigit = (digit, first, second, uid) => {
    var arr = digit?.split('');
    const makeDigit = [
      arr[0]+''+arr[1]+''+arr[2],
      arr[0]+''+arr[2]+''+arr[1],
      arr[1]+''+arr[0]+''+arr[2],
      arr[1]+''+arr[2]+''+arr[0],
      arr[2]+''+arr[0]+''+arr[1],
      arr[2]+''+arr[1]+''+arr[0],
    ]
    const digitArr = [...new Set(makeDigit)];
    const outputArray = digitArr.map((item, index) => {
      if(index === 0){
        return ({ 'digit': item, 'amount': first, 'userid':uid })
      } else {
        return ({ 'digit':item, 'amount': second, 'userid':uid })
      }
    });
    return outputArray
  }
  
  const DefineDigit = (digit, result, numarray, amount, first, second, uid) => {
    if(result?.includes('R') && !result?.includes('E')){
      var newDigit = result?.split('R')
      var firstSplit = newDigit?.[0]
      var firstFirst = firstSplit?.slice(0,3)
      return mixingDigit(firstFirst, first, second, uid)
    } else if(digit.includes('A')){
      return ['000','111','222','333','444','555','666','777','888','999'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('H')){
      return ['000','100','200','300','400','500','600','700','800','900'].map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('F')){
      var newFDigit = digit?.replace('F','')
      var digitFFirst = numarray.filter(num => num?.[0] === newFDigit)
      return digitFFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('M')){
      var newMDigit = digit?.replace('M','')
      var digitMFirst = numarray.filter(num => num?.[1] === newMDigit)
      return digitMFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(digit.includes('L')){
      var newLDigit = digit?.replace('L','')
      var digitLFirst = numarray.filter(num => num?.[2] === newLDigit)
      return digitLFirst.map(item => ({'digit':item,'amount':amount, 'userid':uid}))
    } else if(result?.includes('S')){
      var splitSDigit = result?.split('S')
      var amountS = splitSDigit?.[0]?.length === 0? 
      splitSDigit?.[1]?.slice(2) :
      splitSDigit?.[0]?.length === 1 ?
      splitSDigit?.[1]?.slice(1) :
      splitSDigit?.[1]
      var firstDigit = ''
      var secondDigit = ''
      var thirdDigit = ''
      var sDigitArr = []
      if(splitSDigit?.[0]?.length === 0){
        secondDigit = splitSDigit?.[1]?.[0]
        thirdDigit = splitSDigit?.[1]?.[1]
        sDigitArr = ['0'+secondDigit+thirdDigit, 
          '1'+secondDigit+thirdDigit,
          '2'+secondDigit+thirdDigit,
          '3'+secondDigit+thirdDigit,
          '4'+secondDigit+thirdDigit,
          '5'+secondDigit+thirdDigit,
          '6'+secondDigit+thirdDigit,
          '7'+secondDigit+thirdDigit,
          '8'+secondDigit+thirdDigit,
          '9'+secondDigit+thirdDigit,
        ]
      }else if(splitSDigit?.[0]?.length === 1){
        firstDigit = splitSDigit?.[0]
        thirdDigit = splitSDigit?.[1]?.[0]
        sDigitArr = [firstDigit+'0'+thirdDigit, 
          firstDigit+'1'+thirdDigit,
          firstDigit+'2'+thirdDigit,
          firstDigit+'3'+thirdDigit,
          firstDigit+'4'+thirdDigit,
          firstDigit+'5'+thirdDigit,
          firstDigit+'6'+thirdDigit,
          firstDigit+'7'+thirdDigit,
          firstDigit+'8'+thirdDigit,
          firstDigit+'9'+thirdDigit,
        ]
      }else {
        firstDigit = splitSDigit?.[0]?.[0]
        secondDigit = splitSDigit?.[0]?.[1]
        sDigitArr = [firstDigit+secondDigit+'0', 
          firstDigit+secondDigit+'1',
          firstDigit+secondDigit+'2',
          firstDigit+secondDigit+'3',
          firstDigit+secondDigit+'4',
          firstDigit+secondDigit+'5',
          firstDigit+secondDigit+'6',
          firstDigit+secondDigit+'7',
          firstDigit+secondDigit+'8',
          firstDigit+secondDigit+'9',
        ]
      }
      return sDigitArr.map(item => ({'digit':item,'amount':amountS, 'userid':uid}))
    } else if(result?.includes('E')){
      var newEDigit = result?.split('E')?.[1]?.split(',')
      var amountE = result?.split('E')?.[2]
      var amounts = amountE?.includes('R') && amountE?.[0] !== 'R'? amountE?.split('R'): amountE?.[0] === 'R'? [amountE?.replace('R',''), amountE?.replace('R','')] : [amountE,amountE];
      var modEArr = []
      if(amountE?.includes('R')){
        newEDigit?.forEach((digit) => {
          modEArr?.push(...mixingDigit(digit, amounts?.[0], amounts?.[1]))
        })
      } else {
        newEDigit?.forEach((digit) => {
          modEArr?.push({'digit':digit, 'amount':amounts?.[0]})
        })
      }
      return modEArr?.map(item => ({'digit':item?.digit, 'amount':item?.amount, 'userid':uid}))
    }
    return [{'digit':digit, 'amount':amount, 'userid':uid}]
  }
  
  const useFilteredDetail = (detailAll, check) => {
    return useMemo(() => {
      if (!detailAll || detailAll.length === 0) return [];
  
      return detailAll.filter((his) => {
        return his?.buy === check;
      });
    }, [detailAll, check]);
  };

  const newHistory = useFilteredDetail(historyAll, '1');
  const newBuyHistory = useFilteredDetail(historyAll, '2');
  
  const MakeDigitArr = (newHistory) => {
    var digits = []
    newHistory?.forEach((his) => {
        var result = his?.result
        var newDigit = result?.split('R')
        var firstSplit = newDigit?.[0]
        var splitLen = defineSplitlens(result);
        var digitFormula = result?.slice(0,splitLen)
        var oneAmount = result?.slice(splitLen)
        var digitArr = DefineDigit(digitFormula, result, numarray, oneAmount, result?.includes('R') && result?.split('R')?.[0]?.length > 3? firstSplit?.slice(3):result?.slice(splitLen), result?.includes('R')? newDigit?.[1]:'0', his?.userid);
        digits?.push(digitArr)
    })
    return digits?.flat()
  }

  const newDetail = MakeDigitArr(newHistory)
  const newBuyDetail = MakeDigitArr(newBuyHistory)

  const checkWithAgent = (uid) => {
    return users?.filter((user) => user?.id === uid)
  }

  const [luckerr, setLuckErr] = useState('')
  const SaveLucky = async() => {
    let messageUrl = mainUrl+'api.php?op=threesaveLucky';
    let updateUrl = mainUrl+'api.php?op=threeupdateLucky';
    setLuckErr('')
    if(getLucky?.length > 0){
      if(lucky !== getLucky?.[0]?.lucky){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"lucky="+lucky+"&id="+getLucky?.[0]?.id
        }).then(() => {
          setLuckyReload(luckyreload+1)
        })
      }
    } else {
      await fetch(messageUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"lucky="+lucky+"&daypart="+threeDate?.part+"&date="+threeDate?.month+"&created="+inputDate+'&adminid='+userType?.id
      }).then(() => {
        setLuckyReload(luckyreload+1)
      })
    }
  }
  const SaveLimit = async() => {
    let messageUrl = mainUrl+'api.php?op=threesaveLimit';
    let updateUrl = mainUrl+'api.php?op=threeupdateLimit';
    if(getLimit?.length > 0){
      if(limit !== getLimit?.[0]?.limit){
        await fetch(updateUrl, {
          method:'post',
          headers:{
            'Content-Type':'application/x-www-form-urlencoded'
          },
          body:"limit="+limit+"&id="+getLimit?.[0]?.id
        }).then(() => {
          setLimitReload(limitreload+1)
        })
      }
    } else {
      await fetch(messageUrl, {
        method:'post',
        headers:{
          'Content-Type':'application/x-www-form-urlencoded'
        },
        body:"limit="+limit+"&daypart="+threeDate?.part+"&date="+threeDate?.month+"&created="+inputDate+'&adminid='+userType?.id
      }).then(() => {
        setLimitReload(limitreload+1)
      })
    }
  }

  useEffect(() => {
    setInterval(() => setCurrentDate(moment().tz('Asia/Yangon').format('DD-MMM-YYYY HH:mm:ss A')),5000)
  },[])

  var digitStatus = 'Open'
  var totalAmount = newDetail?.reduce((n, {amount}) => n + parseInt(amount), 0)

  const useDetailAmount = (newDetail) => {
    return useMemo(() => {
      const detailAmount = newDetail.reduce((acc, { amount, digit, ...rest }) => {
        const key = digit;
        if (!acc[key]) {
          acc[key] = { digit, ...rest, amount: 0 };
        }
        acc[key].amount += parseInt(amount, 10);
        return acc;
      }, {});
  
      return Object.values(detailAmount);
    }, [newDetail]);
  };
  const detailAmount = useDetailAmount(newDetail);
  
  const CheckWithAllDigit = (thisDigit) => {
    return useMemo(() => {
      return newDetail?.filter((digit) => digit?.digit === thisDigit);
    }, [thisDigit]);
  }
  
  const sumTotalAllAgent = (thisDigit) => {
    return newDetail?.reduce((total, { digit, amount }) => {
      return digit === thisDigit ? total + parseInt(amount) : total;
    }, 0);
  }

  const checkwithAllAgent = (digit) => {
    const map = digit?.reduce((m, { userid, amount }) => {
      m.set(userid, (m.get(userid) || 0) + parseInt(amount));
      return m;
    }, new Map());
  
    return Array.from(map, ([userid, amount]) => ({ userid, amount }));
  }

  const CheckWithDigit = (digit) => {
    return detailAmount?.filter((detail) => detail?.digit === digit) || []
  }

  const filterLucky = (date, daypart) => {
    return allLucky?.filter((lucky) => lucky?.daypart === daypart && lucky?.date === date) || []
  }
  
  const buyDetailAmount = useMemo(() => {
    if (!newBuyDetail || newBuyDetail.length === 0) return [];
    
    const map = newBuyDetail.reduce((m, { digit, amount }) => {
      const parsedBuy = parseInt(amount, 10);
      if (!isNaN(parsedBuy)) {
        m.set(digit, (m.get(digit) || 0) + parsedBuy);
      }
      return m;
    }, new Map());
    return Array.from(map, ([digit, amount]) => ({ digit, amount }));
  }, [newBuyDetail]);

  const checkWithBuyDigit = (digit) => {
    return buyDetailAmount?.filter((detail) => detail?.digit === digit) || []
  }

  const totalBuyAmountAll = newBuyDetail?.reduce((n, {amount}) => n + parseInt(amount), 0)

  return (
    <div className='adminScreen'>
      <Dialog
        open={removeopen}
        onClose={removehandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontFamily:'textfont'}}>
          {"‌Completed"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{fontFamily:'mmfont'}}>
            ဂဏန်းအားလုံးကို ပြန်ဖွင့်ပီးပါပီ
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{fontFamily:'mmfont'}} onClick={removehandleClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        open={open}
        scroll={scroll}
      >
        <DialogTitle style={{fontFamily:'mmfont'}} sx={{ m: 0, p: 2 }} id="scroll-dialog-title">
          ရောင်းပီးစာရင်း
        </DialogTitle>
        <DialogContent
        >
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <p style={{lineHeight:2, paddingBottom:10, fontFamily:'digit', textAlign:'center', fontSize:19, color:'black'}}>{currentDigit}</p>
            <div>
              <table>
                <tbody>
                <tr className='number'>
                  <td style={{padding:5, fontSize:15, fontFamily:'textfont',
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120}}>Agent</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120}}
                  >ပမာဏ</td>
                  <td
                  style={{padding:5, 
                    border:'1px solid #6798c6',fontSize:15, fontFamily:'mmfont',
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:100}}
                  >ပိတ်မည်</td>
                </tr>
                {checkwithAllAgent(CheckWithAllDigit(currentDigit))?.map((agent, index) => {
                  var getAgent = checkWithAgent(agent?.userid)
                  return (
                  <tr key={index} className='number'>
                    <td style={{padding:5, fontSize:15, fontFamily:'textfont',
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:120}}>{getAgent?.[0]?.username}</td>
                    <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:120}}>{agent?.amount}</td>
                    <td style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:100}}>
                      <Switch size='small' checked={getAgent?.[0]?.closedDigit?.split(',')?.includes(currentDigit)} 
                      onChange={() => handleChangeChecked(getAgent?.[0]?.closedDigit, currentDigit, getAgent?.[0]?.id)} 
                      inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </td>
                  </tr>
                  )
                }) }
                <tr className='number'>
                  <td style={{padding:5, fontSize:15, fontFamily:'mmfont',
                    border:'1px solid #6798c6', 
                    margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center', minWidth:120}}>စုစုပေါင်း</td>
                  <td
                  style={{padding:5, fontSize:15,
                    border:'1px solid #6798c6',
                    margin:1, backgroundColor:'white', color:'black', textAlign:'center', minWidth:150}}
                  >{sumTotalAllAgent(currentDigit)}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className='adminTop'>
        <Button
        style={{textDecoration:'none', backgroundColor:'#2b3944',
        color:'white', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Home
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white', 
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeadminbuy', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Admin buy
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/search', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })} 
        >
          Search
        </Button>
        <Button
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        onClick={() => navigate('/threeprofitloss', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users,
          }
        })} 
        >
          Profit/Loss
        </Button>
        <Button
        onClick={() => navigate('/threesummary', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users,
          }
        })} 
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Summary
        </Button>
        <Button
        onClick={() => navigate('/threeaccount', {
          state:{
            limit:getLimit,
            historyAll:historyAll,
            users:users
          }
        })}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Account
        </Button>
        <Button
        onClick={() => {navigate('/'); handleLogout()}}
        style={{textDecoration:'none', backgroundColor:'white',
        color:'black', textTransform:'capitalize', padding:'1px 5px'}}
        >
          Log out
        </Button>
      </div>
      <div className='wholeHomeScreen'>
        <div className='adminHomeScreen'>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>အမည်</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {userType?.username} (ဒိုင်) | 3D</p>
            </div>
          </div>
          <div className='digitscreen' style={{justifyContent:'space-between', alignItems:'flex-start'}}>
            <div className='topbar' style={{width:'100%', display:'flex'}}>
              <div style={{width:55}}><p className='text'>ဖွင့်ရက်</p></div>
              <div style={{marginLeft: 20, fontSize:13}}>
                <p className='text'>: &nbsp; {threeDate?.dateFrom} မှ </p>
                <p className='text'>&nbsp; &nbsp; {threeDate?.dateTo}</p>
              </div>
            </div>
            
            <div style={{marginRight: 10}}>
            <Button
            style={{padding:'0px 5px', backgroundColor:'white', fontSize:13, width:100,
              textDecoration:'none !important', color:'black', textTransform:'lowercase'}}
            onClick={() => navigate('/')}
            >
              Select Date
            </Button>
            </div>
          </div>
          <div className='digitscreen'>
            <div className='topbar'>
              <p className='text'>Status</p>
            </div>
            <div style={{marginLeft: 20}}>
              <p className='text'>: &nbsp; {digitStatus}</p>
            </div>
          </div>
          <ThreeAdminLimitInput/>
          <div style={{height:2, width:'95%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:10}}/>
          <div style={{padding:10, marginTop:10, justifyContent:'space-between', display:'flex'}}>
            <table>
              <tbody>
              <tr>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >အရောင်း စုစုပေါင်း</td>
                <td
                  className='number'
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:130, color:'black', padding:'2px 10px'}}
                >{totalAmount}</td>
              </tr>
              <tr>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >ပြန်ဝယ်ပီး စုစုပေါင်း</td>
                <td
                  className='number'
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:130, color:'black', padding:'2px 10px'}}
                >{totalBuyAmountAll}</td>
              </tr>
              <tr>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >အရောင်း - ပြန်ဝယ်</td>
                <td
                  className='number'
                  style={{border:'1px solid #6798c6', fontSize:15, fontWeight:'500',
                  margin:1, width:100, color:'black', padding:'2px 10px'}}
                >{totalAmount - totalBuyAmountAll}</td>
              </tr>
              <tr>
                <td
                  style={{border:'1px solid #6798c6', fontFamily:'mmfont',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >ခေါက်ချိုးငွေ Limit</td>
                <td
                  className='number'
                  style={{border:'1px solid #6798c6',
                  margin:1, width:100, color:'white', padding:'2px 2px'}}
                >
                  <Box
                  component="form"
                  sx={{
                      '& > :not(style)': { m: 0, width: '100%', padding:0},
                  }}
                  >
                  <TextField
                    InputProps={{
                      style: {
                        height:30,
                        padding: '0 0px !important',
                        fontSize:15,
                        fontFamily:'textfont'
                      },
                    }}
                    size='small'
                    id="limit-amount"
                    value={limit}
                    placeholder='Limit amount'
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </Box>
                </td>
                <td
                  style={{borderRadius:5,
                  margin:1, color:'white'}}
                >
                  <Button
                  size='small'
                  style={{
                    textDecoration:'none',
                    textTransform:'capitalize',
                    backgroundColor:'#6798c6',
                    color:'white',
                    marginLeft:1,
                  }}
                  onClick={() => SaveLimit()}
                  >
                    Save
                  </Button>
                </td>
              </tr>
              <tr>
                <td
                  style={{border:'1px solid #6798c6',
                  margin:1, backgroundColor:'#6798c6', color:'white', padding:'2px 10px'}}
                >%</td>
                <td
                  className='number'
                  style={{border:'1px solid #6798c6',
                  margin:1, width:100, color:'black', padding:'2px 10px'}}
                >{getLimit?.[0]?.limit === undefined || 
                  getLimit?.[0].limit === '0'?'0':
                  Math.round((totalAmount - totalBuyAmountAll)/parseInt(
                    getLimit?.[0]?.limit===undefined?'1': 
                    getLimit?.[0]?.limit === '0'? 
                    '1':getLimit?.[0]?.limit))}%</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div style={{height:2, width:'95%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:10}}/>
          <div className='secondBoxSecond'>
            <p style={{paddingBottom:luckerr!==''?20:0, color:'red'}}>{luckerr}</p>
            <div className='secondBoxfirst'>
              <p style={{fontFamily:'mmfont'}}>ပေါက်ကွက်</p>
              <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 0, width: 150, padding:0},
                }}
                style={{marginLeft:15}}
                >
                <TextField
                  InputProps={{
                    style: {
                      height:30,
                      padding: '0 5px',
                      fontFamily:'textfont'
                    },
                  }}
                  inputProps={{maxLength:3, inputMode: 'numeric'}}
                  size='small'
                  id="lucky" variant="outlined" 
                  value={lucky}
                  placeholder='Lucky number'
                  onChange={(e) => setLucky(e.target.value)}
                />
              </Box>
              <Button
              onClick={() => SaveLucky()}
              size='small'
              style={{
                textDecoration:'none',
                textTransform:'capitalize',
                backgroundColor:'#6798c6',
                color:'white',
                marginLeft:5,
              }}
              >
                Save
              </Button>
            </div>
            <div className='secondBoxSecondInner'>
              <h4 style={{marginTop:25, fontFamily:'mmfont', marginBottom:10}}>တစ်လစာ ပေါက်ကွက်များ</h4>
              <table
              >
                <thead>
                  <tr className='number'>
                    <td style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      Part
                    </td>
                    <td 
                    className='number'
                    style={{padding:5, 
                      border:'1px solid #2b3944',
                      margin:1, backgroundColor:'#2b3944', color:'white'}}>
                      {threeDate?.month}
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding:5, fontFamily:'mmfont',
                      border:'1px solid #6798c6',
                      margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                      ပထမပတ်
                    </td>
                    <td className='number' style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(threeDate?.month, 'first')?.[0]?.lucky}
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding:5, 
                      border:'1px solid #6798c6', fontFamily:'mmfont',
                      margin:1, backgroundColor:'#6798c6', color:'white', textAlign:'center'}}>
                      ဒုတိယအပတ်
                    </td>
                    <td className='number' style={{padding:5, fontWeight:'500', fontSize:15,
                      border:'1px solid #6798c6',
                      margin:1, color:'black', textAlign:'center'}}>
                      {filterLucky(threeDate?.month, 'second')?.[0]?.lucky}
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
            <div style={{height:2, width:'100%', backgroundColor:'rgba(0,0,0,0.2)', marginLeft:'auto', marginRight:'auto', marginTop:20}}/>
            <div>
              <Button
              onClick={() => navigate('/threehotnumbers')} 
              size='small'
              style={{
                marginTop:20,
                fontSize:14,
                textDecoration:'none',
                textTransform:'capitalize',
                color:'#6798c6',
                border:'2px solid #6798c6',
                fontWeight:'bold',
                fontFamily:'mmfont',
              }}
              >
                Hot number ဟော့ဂဏန်း သတ်မှတ်ရန်
              </Button>
            </div>
            <div>
              <Button
              onClick={() => navigate('/threeadminbuy', {
                state:{
                  limit:getLimit,
                  historyAll:historyAll,
                  users:users
                }
              })} 
              size='small'
              style={{
                marginTop:10,
                fontSize:14,
                textDecoration:'none',
                textTransform:'capitalize',
                color:'#6798c6',
                border:'2px solid #6798c6',
                fontWeight:'bold',
                fontFamily:'mmfont',
              }}
              >
                Admin Buy ပြန်ဝယ်စာရင်း သွင်းရန် (ကာသီး)
              </Button>
            </div>
          </div>
        </div>
        {/*<div className='secondBox'>
          <p> Server time - {currentDate}</p>
          <div className='tableWhole'>
            <Grid
              cellRenderer={Cell}
              columnCount={columnCount}
              columnWidth={cellWidth}
              height={2000}
              rowCount={rowCount}
              rowHeight={cellHeight}
              width={2000}
            />
          </div>
        </div>*/}
        {<div className='secondBox'>
          <p> Server time - {currentDate}</p>
          <div style={{marginTop:20}}>
            <h4 style={{fontFamily:'mmfont'}}>Agent အလိုက် ပိတ်ဂဏန်းများ ပြန်ဖွင့်ရန်</h4>
            <div style={{display:'flex', marginTop:8}}>
            <Select
              size='small'
              sx={{
                height:30,
                width:140,
                color: "black",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'white',
                  border:'2px solid #6798c6'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6798c6',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6798c6',
                },
                '.MuiSvgIcon-root ': {
                  fill: "#6798c6 !important",
                }
              }}
              labelId="select-user"
              id="select-user"
              value={username}
              onChange={handleChange}
              > 
                <MenuItem value="allagents" selected>All agents</MenuItem>
                {
                  users?.sort((a,b) => a?.username.localeCompare(b?.username))?.map((user, index) => 
                  <MenuItem key={index} value={user?.id}>{user?.username}</MenuItem>
                  )
                }
            </Select>
            <Button
            size='small'
            style={{
              textDecoration:'none',
              textTransform:'capitalize',
              backgroundColor:'#6798c6',
              color:'white',
              marginLeft:5,
              fontFamily:'mmfont'
            }}
            onClick={() => removeAgentLimit(username)}
            >
              ပြန်ဖွင့်မည်
            </Button>
            </div>
          </div>
          <div className='tableWhole'>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {-1}
                lastDigit = {50}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {50}
                lastDigit = {100}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>

            <div className='column'>
              <ThreeDigitArr
                firstDigit = {100}
                lastDigit = {150}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {150}
                lastDigit = {200}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {200}
                lastDigit = {250}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {250}
                lastDigit = {300}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {300}
                lastDigit = {350}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {350}
                lastDigit = {400}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {400}
                lastDigit = {450}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {450}
                lastDigit = {500}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {500}
                lastDigit = {550}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {550}
                lastDigit = {600}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {600}
                lastDigit = {650}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {650}
                lastDigit = {700}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {700}
                lastDigit = {750}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {750}
                lastDigit = {800}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {800}
                lastDigit = {850}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
                firstDigit = {850}
                lastDigit = {900}
                numarray = {numarray}
                getLimit = {getLimit}
                checkWithDigit = {CheckWithDigit}
                checkWithBuyDigit = {checkWithBuyDigit}
                handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
              firstDigit = {900}
              lastDigit = {950}
              numarray = {numarray}
              getLimit = {getLimit}
              checkWithDigit = {CheckWithDigit}
              checkWithBuyDigit = {checkWithBuyDigit}
              handleClickOpen = {handleClickOpen}
              />
              
            </div>
            <div className='column'>
              <ThreeDigitArr
              firstDigit = {950}
              lastDigit = {1000}
              numarray = {numarray}
              getLimit = {getLimit}
              checkWithDigit = {CheckWithDigit}
              checkWithBuyDigit = {checkWithBuyDigit}
              handleClickOpen = {handleClickOpen}
              />
            </div>
          </div>
          
        </div>}
      </div>
    </div>
  )
}

export default ThreeAdminHomeScreen
